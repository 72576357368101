import React, { useState, useEffect } from 'react';
import {  TextField } from '@mui/material';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Tabs,
  Tab,
  Box,
  Grid
} from '@mui/material';
import {
  TrendingUp,
  AccountBalance,
  CreditCard,
  SavingsOutlined
} from '@mui/icons-material';
import { CollapsibleHeader } from './CollapsibleHeader';
import { ScoreCard } from './ScoreCard';
import { FinancialGraphs } from './FinancialGraphs';
import { FinancialRankings } from './FinancialRankings';
import { BasicInfoTab } from './Tabs/BasicInfoTab';
import { DebtTab } from './Tabs/DebtTab';
import { EmergencyFundTab } from './Tabs/EmergencyFundTab';
import { InvestmentsTab } from './Tabs/InvestmentsTab';
import {
  calculateEmergencyFundScore,
  calculateDebtScore,
  calculateInvestmentScore,
  calculateRetirementScore,
  calculateComfortScore
} from '../../utils/scoring';
import { getComfortLevel } from '../../utils/formatters';
import {
  getEmergencyFundExplanation,
  getDebtManagementExplanation,
  getInvestmentHealthExplanation,
  getRetirementReadinessExplanation
} from '../../utils/explanations';

const initialFormData = {
  // Basic Information
  monthlyIncomeAfterTax: '',
  monthlyExpenses: '',
  monthlySavings: '',
  currentAge: '',
  retirementAge: '',
  
  // Emergency Fund
  emergencyFundMonths: '0',
  emergencyFundAmount: '',
  
  // Debt Information
  mortgageDebt: '',
  mortgageMonthlyPayment: '',
  mortgageInterestRate: '',
  carLoanDebt: '',
  carLoanMonthlyPayment: '',
  carLoanInterestRate: '',
  creditCardDebt: '',
  creditCardInterestRate: '',
  revolvingLoanDebt: '',
  revolvingLoanMonthlyPayment: '',
  revolvingLoanInterestRate: '',
  miscDebt: '',
  miscDebtMonthlyPayment: '',
  miscDebtInterestRate: '',
  
  // Investments
  annuityValue: '',
  pensionValue: '',
  taxFreeSavings: '',
  stockInvestments: '',
  realEstateInvestments: ''
};

export const ComfortAnalyzer = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState(initialFormData);
  const [showInfo, setShowInfo] = useState(true);
  const [showGraphs, setShowGraphs] = useState(true);
  const [showRankings, setShowRankings] = useState(true);
    const [showSummary, setShowSummary] = useState(true);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const comfortScore = calculateComfortScore(formData);
  const comfortLevel = getComfortLevel(comfortScore);

  return (
         <div className="bg-gradient-to-br from-slate-900 to-slate-800 min-h-screen p-6">
<span className="text-4xl md:text-xl font-semibold">
  <span className="text-[#FF69B4]">FAI</span>
  <span className="text-[#A855F7]">NANCE</span>
</span>
      <Card sx={{ 
        maxWidth: 'lg', 
        mx: 'auto', 
        mt: 4,
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
        borderRadius: '16px',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      }}>
        <CardHeader 
          title={

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrendingUp sx={{ color: '#FF69B4' }} />
              <Typography variant="h5" sx={{ color: '#A855F7' }}>
                Financial Comfort Calculator
              </Typography>
            </Box>

          }
        />
        <CardContent>
          {/* Overall Financial Comfort Score */}
          <Box sx={{
            p: 3,
            mb: 4,
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 2,
          }}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mb: 1,
                color: 'common.white'
              }}>
                <Typography variant="h6">Overall Financial Comfort:</Typography>
                <Typography 
                  variant="h6"
                  sx={{ color: comfortLevel.color }}
                >
                  {comfortLevel.level}
                </Typography>
              </Box>
              <Box sx={{ 
                width: '100%', 
                height: '6px', 
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '3px',
                overflow: 'hidden'
              }}>
                <Box
                  sx={{
                    width: `${comfortScore}%`,
                    height: '100%',
                    bgcolor: comfortScore === 0 ? '#f87171' : comfortLevel.color,
                    transition: 'width 0.5s ease-out'
                  }}
                />
              </Box>
              <Typography sx={{ 
                color: 'common.white',
                mt: 1,
                fontSize: '0.875rem'
              }}>
                {comfortLevel.description}
              </Typography>
            </Box>

            {/* Score Cards Grid */}
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(4, 1fr)'
              },
              gap: 2
            }}>
              <ScoreCard
                title="Emergency Preparedness"
                score={calculateEmergencyFundScore(formData)}
                maxScore={25}
                icon={<AccountBalance />}
                explanation={getEmergencyFundExplanation(formData)}
              />
              <ScoreCard
                title="Debt Management"
                score={calculateDebtScore(formData)}
                maxScore={25}
                icon={<CreditCard />}
                explanation={getDebtManagementExplanation(formData)}
              />
              <ScoreCard
                title="Investment Health"
                score={calculateInvestmentScore(formData)}
                maxScore={25}
                icon={<TrendingUp />}
                explanation={getInvestmentHealthExplanation(formData)}
              />
              <ScoreCard
                title="Retirement Readiness"
                score={calculateRetirementScore(formData)}
                maxScore={25}
                icon={<SavingsOutlined />}
                explanation={getRetirementReadinessExplanation(formData)}
              />
            </Box>
          </Box>

          {/* Tabs Section */}
          <Box sx={{
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 2,
            mb: 3,
            overflow: 'hidden'
          }}>
            <CollapsibleHeader 
              title="Finances"
              isOpen={showInfo}
              onToggle={() => setShowInfo(!showInfo)}
            />
            {showInfo && (
              <Box sx={{ p: 2 }}>
                <Tabs 
                  value={activeTab} 
                  onChange={(e, newValue) => setActiveTab(newValue)}
                  variant="fullWidth"
                  sx={{ 
                    borderBottom: 1, 
                    borderColor: 'rgba(255, 255, 255, 0.1)',
                    mb: 3,
                    '& .MuiTab-root': {
                      color: 'common.white',
                      '&:hover': {
                        color: 'common.white 	 	',
                      }
                    },
                    '& .Mui-selected': {
                      color: '#22d3ee !important', // cyan accent color
                    },
                    '& .MuiTabs-indicator': {
                      backgroundColor: '#22d3ee',
                    }
                  }}
                >
                  <Tab label="Basic Info" />
                  <Tab label="Debt" />
                  <Tab label="Emergency Fund" />
                  <Tab label="Investments" />
                </Tabs>

                  <Box sx={{ py: 2 }}>
                    {activeTab === 0 && (
                      <BasicInfoTab 
                        formData={formData}
                        handleInputChange={handleInputChange}
                      />
                    )}
                    {activeTab === 1 && (
                      <DebtTab 
                        formData={formData}
                        handleInputChange={handleInputChange}
                      />
                    )}
                    {activeTab === 2 && (
                      <EmergencyFundTab 
                        formData={formData}
                        handleInputChange={handleInputChange}
                      />
                    )}
                    {activeTab === 3 && (
                      <InvestmentsTab 
                        formData={formData}
                        handleInputChange={handleInputChange}
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>

          <Box sx={{
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 2,
            mb: 3,
            overflow: 'hidden'
          }}>
            <CollapsibleHeader 
              title="Graphs"
              isOpen={showGraphs}
              onToggle={() => setShowGraphs(!showGraphs)}
            />
            {showGraphs && <FinancialGraphs formData={formData} />}
          </Box>

          {/* Rankings Section */}
          <Box sx={{
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 2,
            overflow: 'hidden'
          }}>
            <CollapsibleHeader 
              title="Your Ranking"
              isOpen={showRankings}
              onToggle={() => setShowRankings(!showRankings)}
            />
            {showRankings && <FinancialRankings formData={formData} />}
			
          </Box>
		             <Box sx={{
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 2,
            mb: 3,
            overflow: 'hidden'
          }}>
            <CollapsibleHeader 
              title="Summary"
              isOpen={showGraphs}
              onToggle={() => setShowSummary(!showSummary)}
            />
          </Box>

		  
        </CardContent>
      </Card>
    </div>
  );
};

export default ComfortAnalyzer;