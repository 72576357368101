import React from 'react';
import { Grid, TextField } from '@mui/material';
const whiteTextField = {
  '& .MuiInputBase-input': {
    color: 'common.white'
  },
  '& .MuiInputLabel-root': {
    color: 'common.white'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)'
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white'
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'common.white'
  }
};

export const InvestmentsTab = ({ formData, handleInputChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Annuity Value"
          name="annuityValue"
          value={formData.annuityValue}
          onChange={handleInputChange}
          type="number"
          variant="outlined"
		  		  sx={whiteTextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Pension Value"
          name="pensionValue"
          value={formData.pensionValue}
          onChange={handleInputChange}
          type="number"
          variant="outlined"
		  		  sx={whiteTextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Tax-Free Savings"
          name="taxFreeSavings"
          value={formData.taxFreeSavings}
          onChange={handleInputChange}
          type="number"
          variant="outlined"
		  		  sx={whiteTextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Stock Investments"
          name="stockInvestments"
          value={formData.stockInvestments}
          onChange={handleInputChange}
          type="number"
          variant="outlined"
		  		  sx={whiteTextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Real Estate Investments"
          name="realEstateInvestments"
          value={formData.realEstateInvestments}
          onChange={handleInputChange}
          type="number"
          variant="outlined"
		  		  sx={whiteTextField}
        />
      </Grid>
    </Grid>
  );
};

export default InvestmentsTab;