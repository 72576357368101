import React from 'react';
import ComfortAnalyzer from './components/ComfortAnalyzer';

function App() {
  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-slate-900 to-slate-800">
      <ComfortAnalyzer />
    </div>
  );
}

export default App;