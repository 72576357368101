export const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);
};

export const getScoreColor = (score, maxScore) => {
  if (score === 0) return '#dc2626';
  const percentage = (score / maxScore) * 100;
  if (percentage >= 80) return '#22c55e';
  if (percentage >= 60) return '#3b82f6';
  if (percentage >= 40) return '#f87171';
  return '#ef4444';
};

export const getComfortLevel = (score) => {
  if (score >= 85) return { 
    level: 'Very Comfortable', 
    color: '#22c55e', 
    description: 'Excellent financial security' 
  };
  if (score >= 70) return { 
    level: 'Comfortable', 
    color: '#3b82f6', 
    description: 'Good financial health' 
  };
  if (score >= 50) return { 
    level: 'Managing', 
    color: '#f97316', 
    description: 'Stable but needs improvement' 
  };
  if (score >= 30) return { 
    level: 'Somewhat Stretched', 
    color: '#f87171', 
    description: 'Several areas need attention' 
  };
  return { 
    level: 'Financially Stretched', 
    color: '	#f87171', 
    description: 'Immediate action recommended' 
  };
};