import React from 'react';
import { Grid, TextField, Box } from '@mui/material';
import { calculateYearsToPayOff } from '../../../utils/calculations';

const whiteTextField = {
  '& .MuiInputBase-input': {
    color: 'common.white'
  },
  '& .MuiInputLabel-root': {
    color: 'common.white'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)'
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white'
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'common.white'
  }
};

export const DebtTab = ({ formData, handleInputChange }) => {
  return (
    <Box>
      {/* Mortgage Section */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Mortgage Amount"
            name="mortgageDebt"
            value={formData.mortgageDebt}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Interest Rate (%)"
            name="mortgageInterestRate"
            value={formData.mortgageInterestRate}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Monthly Payment"
            name="mortgageMonthlyPayment"
            value={formData.mortgageMonthlyPayment}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Years to Pay Off"
            value={calculateYearsToPayOff(
              formData.mortgageDebt,
              formData.mortgageInterestRate,
              formData.mortgageMonthlyPayment
            )}
            disabled
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
      </Grid>

      {/* Car Loan Section */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Car Amount"
            name="carLoanDebt"
            value={formData.carLoanDebt}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Interest Rate (%)"
            name="carLoanInterestRate"
            value={formData.carLoanInterestRate}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Monthly Payment"
            name="carLoanMonthlyPayment"
            value={formData.carLoanMonthlyPayment}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Years to Pay Off"
            value={calculateYearsToPayOff(
              formData.carLoanDebt,
              formData.carLoanInterestRate,
              formData.carLoanMonthlyPayment
            )}
            disabled
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
      </Grid>

      {/* Credit Card Section */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Credit Card Debt"
            name="creditCardDebt"
            value={formData.creditCardDebt}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Interest Rate (%)"
            name="creditCardInterestRate"
            value={formData.creditCardInterestRate}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Monthly Payment"
            name="creditCardMonthlyPayment"
            value={formData.creditCardMonthlyPayment}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Years to Pay Off"
            value={calculateYearsToPayOff(
              formData.creditCardDebt,
              formData.creditCardInterestRate,
              formData.creditCardMonthlyPayment
            )}
            disabled
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
      </Grid>

      {/* Revolving Loan Section */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Revolving Loan Amount"
            name="revolvingLoanDebt"
            value={formData.revolvingLoanDebt}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Interest Rate (%)"
            name="revolvingLoanInterestRate"
            value={formData.revolvingLoanInterestRate}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Monthly Payment"
            name="revolvingLoanMonthlyPayment"
            value={formData.revolvingLoanMonthlyPayment}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Years to Pay Off"
            value={calculateYearsToPayOff(
              formData.revolvingLoanDebt,
              formData.revolvingLoanInterestRate,
              formData.revolvingLoanMonthlyPayment
            )}
            disabled
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
      </Grid>

      {/* Misc Debt Section */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Misc Debt Amount"
            name="miscDebt"
            value={formData.miscDebt}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Interest Rate (%)"
            name="miscDebtInterestRate"
            value={formData.miscDebtInterestRate}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Monthly Payment"
            name="miscDebtMonthlyPayment"
            value={formData.miscDebtMonthlyPayment}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Years to Pay Off"
            value={calculateYearsToPayOff(
              formData.miscDebt,
              formData.miscDebtInterestRate,
              formData.miscDebtMonthlyPayment
            )}
            disabled
            variant="outlined"
					  		  sx={whiteTextField}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DebtTab;