export const calculateMonthlyObligations = (formData) => {
  const monthlyExpenses = Number(formData.monthlyExpenses) || 0;
  
  const creditCardDebt = Number(formData.creditCardDebt) || 0;
  const annualInterestRate = Number(formData.creditCardInterestRate) || 0;
  const monthlyInterestRate = annualInterestRate / 100 / 12;
  
  const creditCardMonthlyPayment = creditCardDebt > 0 ? 
    (creditCardDebt * monthlyInterestRate) + (creditCardDebt * 0.01) : 0;

  const monthlyDebtPayments = 
    Number(formData.mortgageMonthlyPayment) +
    Number(formData.carLoanMonthlyPayment) +
    Number(formData.revolvingLoanMonthlyPayment) +
    Number(formData.miscDebtMonthlyPayment) +
    creditCardMonthlyPayment;

  return monthlyExpenses + monthlyDebtPayments;
};

export const calculateEmergencyFundMonths = (formData) => {
  const monthlyObligations = calculateMonthlyObligations(formData);
  if (monthlyObligations === 0) return 0;
  
  const emergencyFund = Number(formData.emergencyFundAmount) || 0;
  return Math.ceil(emergencyFund / monthlyObligations);
};

export const calculateYearsToPayOff = (principal, annualRate, monthlyPayment) => {
  const p = Number(principal);
  const r = Number(annualRate);
  const pmt = Number(monthlyPayment);

  if (!p || !r || !pmt) return 0;
  if (pmt <= 0) return Infinity;

  const monthlyRate = r / 100 / 12;
  const monthlyInterest = p * monthlyRate;
  
  if (pmt <= monthlyInterest) return Infinity;

  const months = -Math.log(1 - (p * monthlyRate) / pmt) / Math.log(1 + monthlyRate);
  return Number((months / 12).toFixed(1));
};

export const generateCashflowData = (formData) => {
  const currentAge = Number(formData.currentAge) || 25;
  const monthlyIncome = Number(formData.monthlyIncomeAfterTax) || 0;
  const monthlyExpenses = Number(formData.monthlyExpenses) || 0;
  const emergencyFund = Number(formData.emergencyFundAmount) || 0;
  const monthlySavings = Number(formData.monthlySavings) || 0;
  
  const monthlyDebtPayments = 
    Number(formData.mortgageMonthlyPayment) +
    Number(formData.carLoanMonthlyPayment) +
    Number(formData.revolvingLoanMonthlyPayment) +
    Number(formData.miscDebtMonthlyPayment);

  const data = [];
  let currentCashflow = emergencyFund;
  
  for (let age = currentAge; age <= 80; age++) {
    const annualIncome = monthlyIncome * 12;
    const annualExpenses = monthlyExpenses * 12;
    const annualDebtPayments = monthlyDebtPayments * 12;
    const annualSavings = monthlySavings * 12;
    
    currentCashflow = currentCashflow + annualIncome - annualExpenses - annualDebtPayments;

    data.push({
      age: age,
      cashflow: Math.max(0, currentCashflow),
      expenses: annualExpenses + annualDebtPayments,
      savings: annualSavings
    });
  }

  return data;
};

export const generateRetirementData = (formData) => {
  const currentAge = Number(formData.currentAge) || 25;
  const retirementAge = Number(formData.retirementAge) || 65;
  const monthlyExpenses = Number(formData.monthlyExpenses) || 0;
  const monthlySavings = Number(formData.monthlySavings) || 0;
  
  const currentInvestments = 
    Number(formData.annuityValue) +
    Number(formData.pensionValue) +
    Number(formData.taxFreeSavings) +
    Number(formData.stockInvestments) +
    Number(formData.realEstateInvestments);

  const data = [];
  const INFLATION_RATE = 0.06;
  const INVESTMENT_RETURN = 0.07;
  
  let projectedSavings = currentInvestments;
  let requiredIncome = monthlyExpenses * 12;

  for (let age = currentAge; age <= 80; age++) {
    if (age > currentAge) {
      requiredIncome *= (1 + INFLATION_RATE);
    }

    if (age < retirementAge) {
      projectedSavings = (projectedSavings * (1 + INVESTMENT_RETURN)) + (monthlySavings * 12);
    } else {
      projectedSavings = (projectedSavings - requiredIncome) * (1 + INVESTMENT_RETURN);
    }

    data.push({
      age: age,
      requiredIncome: Math.round(requiredIncome),
      projectedSavings: Math.round(Math.max(0, projectedSavings))
    });
  }

  return data;
};