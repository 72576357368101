import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { calculateEmergencyFundMonths } from '../../../utils/calculations';
const whiteTextField = {
  '& .MuiInputBase-input': {
    color: 'common.white'
  },
  '& .MuiInputLabel-root': {
    color: 'common.white'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)'
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white'
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'common.white'
  }
};

export const EmergencyFundTab = ({ formData, handleInputChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Emergency Fund Amount"
          name="emergencyFundAmount"
          value={formData.emergencyFundAmount}
          onChange={handleInputChange}
          type="number"
          variant="outlined"
		  		  sx={whiteTextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" sx={{ p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
          Months of Coverage: {calculateEmergencyFundMonths(formData)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmergencyFundTab;