import { calculateMonthlyObligations, calculateEmergencyFundMonths } from './calculations';
import { formatCurrency } from './formatters';

export const getEmergencyFundExplanation = (formData) => {
  const monthsCovered = calculateEmergencyFundMonths(formData);
  const monthlyObligations = calculateMonthlyObligations(formData);
  
  return `Emergency Fund Coverage:\n` +
    `• Monthly obligations: ${formatCurrency(monthlyObligations)}\n` +
    `• Emergency fund amount: ${formatCurrency(Number(formData.emergencyFundAmount))}\n` +
    `• Months of coverage: ${monthsCovered}\n\n` +
    `Score Breakdown:\n` +
    `• 12+ months: 25 points\n` +
    `• 6-11 months: 20 points\n` +
    `• 3-5 months: 15 points\n` +
    `• 1-2 months: 10 points\n` +
    `• <1 month: 0 points`;
};

export const getDebtManagementExplanation = (formData) => {
  const monthlyIncome = Number(formData.monthlyIncomeAfterTax) || 1;
  const totalMonthlyDebt = 
    Number(formData.mortgageMonthlyPayment) +
    Number(formData.carLoanMonthlyPayment) +
    Number(formData.revolvingLoanMonthlyPayment) +
    Number(formData.miscDebtMonthlyPayment);
  
  const dti = totalMonthlyDebt / monthlyIncome;
  const creditCardToIncome = Number(formData.creditCardDebt) / (monthlyIncome * 12);
  
  return `Debt Management Analysis:\n` +
    `• Debt-to-Income Ratio: ${(dti * 100).toFixed(1)}%\n` +
    `• Credit Card Debt to Annual Income: ${(creditCardToIncome * 100).toFixed(1)}%\n\n` +
    `Deductions:\n` +
    `• DTI > 43%: -20 points\n` +
    `• DTI > 36%: -15 points\n` +
    `• DTI > 28%: -10 points\n` +
    `• Credit card debt > 50% annual income: -10 points\n\n` +
    `${dti > 0.43 ? '⚠️ High debt-to-income ratio' : ''}` +
    `${creditCardToIncome > 0.5 ? '\n⚠️ High credit card debt' : ''}`;
};

export const getInvestmentHealthExplanation = (formData) => {
  const monthlyIncome = Number(formData.monthlyIncomeAfterTax) || 1;
  const annualIncome = monthlyIncome * 12;
  const currentAge = Number(formData.currentAge) || 30;
  const targetMultiplier = Math.max(1, (currentAge - 25) / 5);
  const targetInvestments = annualIncome * targetMultiplier;
  
  const totalInvestments = 
    Number(formData.annuityValue) +
    Number(formData.pensionValue) +
    Number(formData.taxFreeSavings) +
    Number(formData.stockInvestments) +
    Number(formData.realEstateInvestments);
  
  const investments = {
    protected: Number(formData.annuityValue) + Number(formData.pensionValue),
    taxAdvantaged: Number(formData.taxFreeSavings),
    growth: Number(formData.stockInvestments),
    tangible: Number(formData.realEstateInvestments)
  };
  
  const significantAllocations = Object.entries(investments)
    .filter(([_, value]) => (value / totalInvestments) > 0.1)
    .map(([key, value]) => `• ${key}: ${((value / totalInvestments) * 100).toFixed(1)}%`)
    .join('\n');
  
  return `Investment Health Analysis:\n` +
    `Target Investments (${targetMultiplier}x annual income): ${formatCurrency(targetInvestments)}\n` +
    `Total Investments: ${formatCurrency(totalInvestments)}\n\n` +
    `Portfolio Allocation:\n${significantAllocations}\n\n` +
    `Scoring:\n` +
    `• Age-based target (15 pts): ${totalInvestments >= targetInvestments ? 'Met' : 'Not met'}\n` +
    `• Diversification (10 pts): ${Object.values(investments).filter(v => v > 0).length} categories`;
};

export const getRetirementReadinessExplanation = (formData) => {
  const currentAge = Number(formData.currentAge);
  const retirementAge = Number(formData.retirementAge);
  const monthlyExpenses = Number(formData.monthlyExpenses);
  
  const currentInvestments = 
    Number(formData.annuityValue) +
    Number(formData.pensionValue) +
    Number(formData.taxFreeSavings) +
    Number(formData.stockInvestments) +
    Number(formData.realEstateInvestments);
    
  const yearsToRetirement = retirementAge - currentAge;
  
  const projectedCurrentInvestments = currentInvestments * Math.pow(1.07, yearsToRetirement);
  const annualSavings = Number(formData.monthlySavings) * 12;
  const projectedSavings = annualSavings * ((Math.pow(1.07, yearsToRetirement) - 1) / 0.07);
  const totalRetirementSavings = projectedCurrentInvestments + projectedSavings;

  return `Retirement Analysis:\n\n` +
    `Current Status:\n` +
    `• Current Age: ${currentAge}\n` +
    `• Retirement Age: ${retirementAge}\n` +
    `• Current Monthly Expenses: ${formatCurrency(monthlyExpenses)}\n` +
    `• Monthly Savings: ${formatCurrency(Number(formData.monthlySavings))}\n\n` +
    `Projections:\n` +
    `• Total Savings at Retirement: ${formatCurrency(totalRetirementSavings)}\n\n` +
    `Assumptions:\n` +
    `• 6% Annual Inflation\n` +
    `• 7% Investment Return\n` +
    `• No Additional Income Sources`;
};