import { calculateMonthlyObligations, calculateEmergencyFundMonths } from './calculations';

export const hasEnteredData = (category, formData) => {
  switch (category) {
    case 'emergency':
      return formData.emergencyFundAmount !== '' && 
             formData.monthlyExpenses !== '' && 
             calculateMonthlyObligations(formData) > 0;
      
    case 'debt':
      return (
        Number(formData.mortgageDebt) > 0 ||
        Number(formData.carLoanDebt) > 0 ||
        Number(formData.creditCardDebt) > 0 ||
        Number(formData.revolvingLoanDebt) > 0 ||
        Number(formData.miscDebt) > 0
      );
      
    case 'investment':
      return (
        Number(formData.annuityValue) > 0 ||
        Number(formData.pensionValue) > 0 ||
        Number(formData.taxFreeSavings) > 0 ||
        Number(formData.stockInvestments) > 0 ||
        Number(formData.realEstateInvestments) > 0
      );
      
    case 'retirement':
      return (
        formData.monthlyIncomeAfterTax !== '' &&
        formData.monthlySavings !== '' &&
        formData.monthlyExpenses !== '' &&
        formData.currentAge !== '' &&
        formData.retirementAge !== ''
      );
      
    default:
      return false;
  }
};

export const calculateEmergencyFundScore = (formData) => {
  if (!hasEnteredData('emergency', formData)) return 0;
  
  const monthsCovered = calculateEmergencyFundMonths(formData);
  
  if (monthsCovered >= 12) return 25;
  if (monthsCovered >= 6) return 20;
  if (monthsCovered >= 3) return 15;
  if (monthsCovered >= 1) return 10;
  return 0;
};

export const calculateDebtScore = (formData) => {
  if (!hasEnteredData('debt', formData)) return 0;
  
  let score = 25;
  const monthlyIncome = Number(formData.monthlyIncomeAfterTax) || 1;
  
  const totalMonthlyDebt = 
    Number(formData.mortgageMonthlyPayment) +
    Number(formData.carLoanMonthlyPayment) +
    Number(formData.revolvingLoanMonthlyPayment) +
    Number(formData.miscDebtMonthlyPayment) +
    (Number(formData.creditCardDebt) * 0.03);
  
  const dti = totalMonthlyDebt / monthlyIncome;
  
  if (dti > 0.43) score -= 20;
  else if (dti > 0.36) score -= 15;
  else if (dti > 0.28) score -= 10;
  
  const creditCardToIncome = Number(formData.creditCardDebt) / monthlyIncome;
  if (creditCardToIncome > 0.5) score -= 10;
  
  return Math.max(0, score);
};

export const calculateInvestmentScore = (formData) => {
  if (!hasEnteredData('investment', formData)) return 0;
  
  let score = 0;
  const monthlyIncome = Number(formData.monthlyIncomeAfterTax) || 1;
  const annualIncome = monthlyIncome * 12;
  const currentAge = Number(formData.currentAge) || 30;
  
  const totalInvestments = 
    Number(formData.annuityValue) +
    Number(formData.pensionValue) +
    Number(formData.taxFreeSavings) +
    Number(formData.stockInvestments) +
    Number(formData.realEstateInvestments);
  
  const targetMultiplier = Math.max(1, (currentAge - 25) / 5);
  const targetInvestments = annualIncome * targetMultiplier;
  
  if (totalInvestments >= targetInvestments) score += 15;
  else if (totalInvestments >= targetInvestments * 0.75) score += 10;
  else if (totalInvestments >= targetInvestments * 0.5) score += 5;
  
  const investments = {
    protected: Number(formData.annuityValue) + Number(formData.pensionValue),
    taxAdvantaged: Number(formData.taxFreeSavings),
    growth: Number(formData.stockInvestments),
    tangible: Number(formData.realEstateInvestments)
  };
  
  const significantAllocations = Object.values(investments)
    .filter(value => (value / totalInvestments) > 0.1).length;
  
  score += significantAllocations * 2;
  
  return Math.min(25, score);
};

export const calculateRetirementScore = (formData) => {
  if (!hasEnteredData('retirement', formData)) return 0;
  
  const currentAge = Number(formData.currentAge);
  const retirementAge = Number(formData.retirementAge);
  const monthlyExpenses = Number(formData.monthlyExpenses);
  const monthlySavings = Number(formData.monthlySavings);
  
  const currentInvestments = 
    Number(formData.annuityValue) +
    Number(formData.pensionValue) +
    Number(formData.taxFreeSavings) +
    Number(formData.stockInvestments) +
    Number(formData.realEstateInvestments);
  
  const INFLATION_RATE = 0.06;
  const INVESTMENT_RETURN = 0.05;
  const yearsToRetirement = retirementAge - currentAge;
  
  // Project savings at retirement
  const projectedCurrentInvestments = currentInvestments * Math.pow(1.07, yearsToRetirement);
  const annualSavings = monthlySavings * 12;
  const projectedSavings = annualSavings * ((Math.pow(1.07, yearsToRetirement) - 1) / 0.07);
  const totalRetirementSavings = projectedCurrentInvestments + projectedSavings;
  
  let remainingSavings = totalRetirementSavings;
  let yearsMonyLasts = 0;
  let currentYearExpenses = monthlyExpenses * 12;
  
  while (remainingSavings > 0 && (retirementAge + yearsMonyLasts) < 80) {
    currentYearExpenses *= (1 + INFLATION_RATE);
    remainingSavings -= currentYearExpenses;
    if (remainingSavings > 0) {
      remainingSavings *= (1 + INVESTMENT_RETURN);
      yearsMonyLasts++;
    }
  }
  
  const finalAge = retirementAge + yearsMonyLasts;
  
  if (finalAge >= 80) return 25;
  if (finalAge >= 75) return 20;
  if (finalAge >= 70) return 15;
  if (finalAge >= 65) return 10;
  return 5;
};

export const calculateComfortScore = (formData) => {
  return (
    calculateEmergencyFundScore(formData) +
    calculateDebtScore(formData) +
    calculateInvestmentScore(formData) +
    calculateRetirementScore(formData)
  );
};