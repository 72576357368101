import React from 'react';
import { Paper, Box, Typography, Tooltip, LinearProgress } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getScoreColor } from '../../utils/formatters';

export const ScoreCard = ({
  title,
  score,
  maxScore,
  icon,
  explanation
}) => {
  return (
    <Paper 
      sx={{ 
        p: 2,
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
        borderRadius: '16px',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.15)',
          transform: 'translateY(-2px)',
        }
      }}
      elevation={0}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1, 
        mb: 1,
        color: 'common.white' // Updated to grey
      }}>
        {icon && React.cloneElement(icon, { 
          sx: { color: 'common.white' } // Updated icon color
        })}
        <Typography 
          variant="subtitle1" 
          sx={{ 
            fontWeight: 'medium',
            color: 'common.white' // Updated to grey
          }}
        >
          {title}
        </Typography>
        <Tooltip 
          title={
            <Typography style={{ 
              whiteSpace: 'pre-line',
              color: 'common.white' // Updated tooltip text color
            }}>
              {explanation}
            </Typography>
          }
          arrow
          placement="top"
          sx={{ ml: 'auto' }}
        >
          <InfoOutlinedIcon 
            sx={{ 
              cursor: 'pointer',
              fontSize: '1.1rem',
              color: 'common.white', // Updated icon color
              '&:hover': {
                color: 'common.white'
              }
            }} 
          />
        </Tooltip>
      </Box>

      <Box>
        <LinearProgress 
          variant="determinate" 
          value={(score / maxScore) * 100}
          sx={{
            height: 6,
            borderRadius: 3,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getScoreColor(score, maxScore),
              transition: 'transform 0.5s ease-out'
            }
          }}
        />
        <Typography 
          variant="body2" 
          sx={{ 
            mt: 0.5, 
            color: 'common.white', // Updated to grey
            fontSize: '0.875rem'
          }}
        >
          Score: {score}/{maxScore}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ScoreCard;