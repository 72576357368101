import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Tooltip as RechartsTooltip } from 'recharts';
import { formatCurrency } from '../../utils/formatters';
import { generateCashflowData, generateRetirementData } from '../../utils/calculations';

export const FinancialGraphs = ({ formData }) => {
  const [activeGraphTab, setActiveGraphTab] = useState(0);
  const cashflowData = generateCashflowData(formData);
  const retirementData = generateRetirementData(formData);
  const retirementAge = Number(formData.retirementAge) || 65;

  return (
    <div className="mt-4">
      {/* Tabs Navigation */}
      <div className="mb-4 border-b border-white/10">
        <nav className="-mb-px flex gap-4">
          <button
            className={`py-2 px-4 font-medium text-sm border-b-2 transition-colors ${
              activeGraphTab === 0 
                ? 'border-cyan-400 text-cyan-400' 
                : 'border-transparent text-white hover:text-cyan-400 hover:border-white/30'
            }`}
            onClick={() => setActiveGraphTab(0)}
          >
            Cashflow
          </button>
          <button
            className={`py-2 px-4 font-medium text-sm border-b-2 transition-colors ${
              activeGraphTab === 1 
                ? 'border-cyan-400 text-cyan-400' 
                : 'border-transparent text-white hover:text-cyan-400 hover:border-white/30'
            }`}
            onClick={() => setActiveGraphTab(1)}
          >
            Retirement
          </button>
        </nav>
      </div>

      {/* Charts */}
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          {activeGraphTab === 0 ? (
            <LineChart data={cashflowData} margin={{ top: 20, right: 30, left: 70, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
              <XAxis 
                dataKey="age" 
                label={{ value: 'Age', position: 'bottom', fill: 'white' }}
                stroke="white"
                tick={{ fill: 'white' }}
              />
              <YAxis 
                domain={[0, 5000000]}
                ticks={[100000, 1000000, 2000000, 3000000, 4000000, 5000000]}
                tickFormatter={formatCurrency}
                label={{ 
                  value: 'Amount (ZAR)', 
                  angle: -90, 
                  position: 'insideLeft',
                  fill: 'white',
                  offset: -60
                }}
                stroke="white"
                tick={{ fill: 'white' }}
              />
              <RechartsTooltip 
                formatter={(value) => formatCurrency(value)}
                labelFormatter={(label) => `Age: ${label}`}
                contentStyle={{
                  backgroundColor: 'rgba(0,0,0,0.8)',
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '4px',
                  color: 'white'
                }}
              />
              <Line 
                type="monotone" 
                dataKey="cashflow" 
                stroke="#22d3ee"  // cyan-400
                name="Available Cashflow"
                strokeWidth={2}
              />
              <Line 
                type="monotone" 
                dataKey="expenses" 
                stroke="#f87171"  // red-400
                name="Annual Expenses"
                strokeWidth={2}
              />
              <Line 
                type="monotone" 
                dataKey="savings" 
                stroke="#60a5fa"  // blue-400
                name="Annual Savings"
                strokeWidth={2}
              />
            </LineChart>
          ) : (
            <LineChart 
              data={retirementData} 
              margin={{ top: 20, right: 30, left: 70, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
              <XAxis 
                dataKey="age" 
                label={{ value: 'Age', position: 'bottom', fill: 'white' }}
                stroke="white"
                tick={{ fill: 'white' }}
              />
              <YAxis 
                domain={[0, 'auto']}
                tickFormatter={formatCurrency}
                label={{ 
                  value: 'Amount (ZAR)', 
                  angle: -90, 
                  position: 'insideLeft',
                  fill: 'white',
                  offset: -60
                }}
                stroke="white"
                tick={{ fill: 'white' }}
              />
              <RechartsTooltip 
                formatter={(value) => formatCurrency(value)}
                labelFormatter={(label) => `Age: ${label}`}
                contentStyle={{
                  backgroundColor: 'rgba(0,0,0,0.8)',
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '4px',
                  color: 'white'
                }}
              />
              <ReferenceLine 
                x={retirementAge} 
                stroke="rgba(255,255,255,0.5)" 
                label={{ 
                  value: "Retirement Age", 
                  position: "top",
                  fill: "white"
                }} 
                strokeDasharray="3 3"
              />
              <Line 
                type="monotone" 
                dataKey="requiredIncome" 
                stroke="#22d3ee"  // cyan-400
                name="Required Income (with inflation)"
                strokeWidth={2}
              />
              <Line 
                type="monotone" 
                dataKey="projectedSavings" 
                stroke="#f87171"  // red-400
                name="Projected Savings"
                strokeWidth={2}
              />
            </LineChart>
          )}
        </ResponsiveContainer>
      </div>
      
      {/* Retirement Analysis Box */}
      {activeGraphTab === 1 && (
        <div className="mt-4 p-4 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10">
          <h3 className="font-semibold mb-2 text-white">Retirement Projection Analysis</h3>
          <ul className="list-disc pl-5 space-y-1 text-white">
            <li>Cyan line shows required income increasing with 6% annual inflation</li>
            <li>Red line shows projected savings based on current investments and monthly savings</li>
            <li>Vertical line indicates your retirement age ({retirementAge})</li>
            <li>Assumes 7% annual investment return</li>
            {retirementData[retirementData.length - 1].projectedSavings <= 0 && (
              <li className="text-red-400 font-semibold">
                Warning: Projected savings may not be sufficient to maintain desired lifestyle through retirement
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FinancialGraphs;