import React from 'react';

export const FinancialRankings = ({ formData }) => {
  const monthlyIncome = Number(formData.monthlyIncomeAfterTax);
  const totalMonthlyDebt = 
    Number(formData.mortgageMonthlyPayment) +
    Number(formData.carLoanMonthlyPayment) +
    Number(formData.revolvingLoanMonthlyPayment) +
    Number(formData.miscDebtMonthlyPayment);

  const getIncomePercentile = (income) => {
    if (income > 80000) return 95;
    if (income > 45000) return 75;
    if (income > 15000) return 50;
    if (income > 2500) return 25;
    return 10;
  };

  const getDebtPercentile = (debt, income) => {
    if ((debt/income) < 0.25) return 95;
    if ((debt/income) < 0.40) return 75;
    if ((debt/income) < 0.68) return 50;
    if ((debt/income) < 0.80) return 25;
    return 10;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="p-4 bg-white rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Income Ranking</h3>
        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-between">
            <div>
              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-green-200 text-green-800">
                Your Percentile: {getIncomePercentile(monthlyIncome)}
              </span>
            </div>
          </div>
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
            <div 
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
              style={{ width: `${getIncomePercentile(monthlyIncome)}%` }}
            />
          </div>
        </div>
      </div>

      <div className="p-4 bg-white rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Debt Ranking</h3>
        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-between">
            <div>
              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-red-200 text-red-800">
                Your Percentile: {getDebtPercentile(totalMonthlyDebt, monthlyIncome)}
              </span>
            </div>
          </div>
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-red-200">
            <div 
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
              style={{ width: `${getDebtPercentile(totalMonthlyDebt, monthlyIncome)}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialRankings;