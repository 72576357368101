import React from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';

export const CollapsibleHeader = ({
  title,
  isOpen,
  onToggle
}) => (
  <div 
    className="flex items-center justify-between w-full text-white p-3 mb-4 cursor-pointer bg-gradient-to-br from-slate-900 to-slate-800 rounded-lg hover:bg-gray-200 transition-colors"
    onClick={onToggle}
  >
    <h2 className="text-lg font-semibold">{title}</h2>
    {isOpen ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
  </div>
);

export default CollapsibleHeader;